import React, { useState } from "react";
import {
  FaUser,
  FaList,
  FaHome,
  FaNetworkWired,
  FaCog,
  FaSignOutAlt,
  FaChevronLeft,
  FaChevronRight,
  FaDollarSign,
  FaKey,
  FaWrench,
  FaChevronUp,
  FaChevronDown,
} from "react-icons/fa";
import "./Sidebar.css";
import { RiShutDownLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useUser } from "../context/UserContext";
import Cookies from 'js-cookie';


import fireflame from "../../Assist/Fire-Flame.png";
import creditsRemain from "../../Assist/creditRemaining.png";
import { Tooltip } from 'react-tooltip'
import { deleteCookie } from "../../Utils/Function";
import aboutUsImage from "../../Assist/navbarLogo.png"


const Sidebar = ({ isSidebarOpen, toggleSidebar }) => {
  const [tooltip, setTooltip] = useState("");
  const [isTopbarOpen, setIsTopbarOpen] = useState(false);
  const navigate = useNavigate();
  // const user = useUser();
  // const loginStreak = user?.loginStreak || 0;
  // const creditsRemaining = user?.creditsRemaining || 0;
  const { loginStreak, creditsRemaining } = useUser();


  const toggleTopbar = () => {
    setIsTopbarOpen(!isTopbarOpen);
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleLogout = () => {
    try {
      // Clear user data from local storage
      localStorage.removeItem("userId");
      localStorage.removeItem("token");
      Cookies.remove('auth_token');
      deleteCookie('auth_token');
      localStorage.removeItem("loginCount")

      navigate("/");
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  return (
    <>
      {/* Sidebar for Larger Screens */}
      <aside className={`sidebar ${isSidebarOpen ? "open" : "closed"}`}>
        <div className="sidebar-toggle" onClick={toggleSidebar}>
          {isSidebarOpen ? (
            <FaChevronLeft className="sidebar-toggle-icon" />
          ) : (
            <FaChevronRight className="sidebar-toggle-icon" />
          )}
        </div>

        <div className="sidebar-content">
          <div className="sidebar-items">
            {[
              { icon: FaHome, label: "Home", path: "/ardraGPTPage", tooltip: "Go to the Home page" },
              { icon: FaUser, label: "Profile", path: "/profile", tooltip: "View and edit your profile" },
              { icon: FaList, label: "My Prompts", path: "/prompt", tooltip: "Access your saved prompts" },
            ].map((item, index) => (
              <div
                className="sidebar-item"
                key={index}
                onClick={() => handleNavigation(item.path)}

                data-tooltip-id="my-tooltip"
                data-tooltip-content={item.tooltip}
                data-tooltip-place="right"
                data-tooltip-position-strategy="fixed"
              >
                {item.text ? (
                  <span className="text-icon">{item.text}</span>
                ) : (
                  <item.icon className="icon" style={!isSidebarOpen ? { margin: "0px" } : {}} />
                )}
                {isSidebarOpen && <span>{item.label}</span>}

              </div>
            ))}
          </div>
          <div
            className="sidebar-item"
            onClick={handleNavigation.bind(null, "/buyToken")}

            data-tooltip-id="my-tooltip"
            data-tooltip-content="Purchase tokens to unlock premium features"
            data-tooltip-place="right"
            data-tooltip-position-strategy="fixed"
          >
            <FaDollarSign className="icon" />

            {isSidebarOpen && <span>Buy Token</span>}
          </div>
          <Tooltip id="my-tooltip" style={{
            border: "1px solid red", zIndex: "100",
            color: "white",
            padding: "8px",
            borderRadius: "4px",
            width: "200px", // Set a fixed width to force wrapping
            wordWrap: "break-word", // Ensure long words break
            textAlign: "center",
          }} />
          <div
            className={`sidebar-sub-item ${isSidebarOpen ? "row" : "column"}`}
          >


            <span className="icon" data-tooltip-id="my-tooltip"
              data-tooltip-content={(`Login Streak: ${loginStreak} days`)}
              data-tooltip-place="right"
              data-tooltip-position-strategy="fixed" >
              <img src={fireflame} alt="loginstreak" className="fire-icon" />
            </span>

            {isSidebarOpen && (
              <span className="token-count"
              >{loginStreak} days</span>
            )}
            <span className="icon" data-tooltip-id="my-tooltip"
              data-tooltip-content={(`Credits Remaining: ${creditsRemaining}`)}
              data-tooltip-place="right"
              data-tooltip-position-strategy="fixed"> <img src={creditsRemain} alt="creditRemain" className="credit-icon" /></span>
            {isSidebarOpen && (
              <span className="token-count" >{creditsRemaining}</span>
            )}
          </div>

          <div className="sidebar-bottom">
            {/* About Us Section */}
            <div className="sidebar-item-bottom"
              onClick={() => window.location.href = "https://ardra.ai"}
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Learn more about Ardra AI"
              data-tooltip-place="right"
              data-tooltip-position-strategy="fixed"
            >
              <img src={aboutUsImage} alt="About Us" className="about-us-icon" />
              {isSidebarOpen && <span style={{ textWrap: "nowrap" }}>About Us</span>}
            </div>
            <div className="sidebar-item-bottom" onClick={handleLogout} data-tooltip-id="my-tooltip"
              data-tooltip-content="Logout from here"
              data-tooltip-place="right"
              data-tooltip-position-strategy="fixed">
              <RiShutDownLine className="icon" />
              {isSidebarOpen && <span>Logout</span>}
            </div>
          </div>
        </div>
      </aside>

      {/* Topbar for Small Devices */}
      <nav className={`topbar ${isTopbarOpen ? "open" : "closed"}`}>
        <div className="topbar-toggle" onClick={toggleTopbar}>
          {isTopbarOpen ? (
            <FaChevronUp className="topbar-toggle-icon" />
          ) : (
            <FaChevronDown className="topbar-toggle-icon" />
          )}
        </div>
        {isTopbarOpen && (
          <div className="topbar-content">
            {/* Small device credit and streak */}
            <div className="topbar-sub-item">
              <div className="icon" data-tooltip-id="my-tooltip"
                data-tooltip-content={(`Login Streak: ${loginStreak} days`)}
                data-tooltip-place="right"
                data-tooltip-position-strategy="fixed">
                <img src={fireflame} alt="loginstreak" className="fire-icon" />
              </div>
              <span className="token-count">{loginStreak} days</span>
              <div className="icon" data-tooltip-id="my-tooltip"
                data-tooltip-content={(`Credits Remaining: ${creditsRemaining}`)}
                data-tooltip-place="right"
                data-tooltip-position-strategy="fixed" >
                <img src={creditsRemain} alt="creditRemain" className="credit-icon" />
              </div>
              <span className="token-count">{creditsRemaining}</span>
            </div>
            {/* Navigation items */}
            {[
              { text: "AI", label: "Home", path: "/ardraGPTPage", tooltip: "Go to the Home page" },
              { icon: FaUser, label: "Profile", path: "/profile", tooltip: "View and edit your profile" },
              { icon: FaList, label: "My Prompts", path: "/prompt", tooltip: "Access your saved prompts" },
              { icon: FaDollarSign, label: "Buy Token", path: "/buy-token", tooltip: "Buy tokens for premium features" },
              { icon: RiShutDownLine, label: "Logout", path: "/", tooltip: "Log out from your account" },
            ].map((item, index) => (
              <div
                className="topbar-item"
                key={index}
                onClick={
                  item.label === "Logout"
                    ? handleLogout
                    : () => handleNavigation(item.path)
                }

              >
                {item.text ? (
                  <span className="topbar-text-icon">{item.text}</span>
                ) : (
                  <item.icon className="icon" />
                )}
                <span>{item.label}</span>
              </div>
            ))}
          </div>
        )}
      </nav>

    </>
  );
};

export default Sidebar;
