import React, { useState, useEffect } from "react";
import Navbar from "../Component/Navbar_GPTPage/Navbar_GPTPage";
import VoiceRecorder from "../Component/VoiceRecorder/VoiceRecorder";
import Footer from "../Component/Footer/Footer";
import "../../src/styles/tailwind.css";
import Sidebar from "../Component/Sidebar/Sidebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useNavigate } from "react-router-dom";
import Homepage_Chat from "../Component/HomepageChat/Homepage_Chat";
const ArdraGPTNew = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = () => {
      const token = localStorage.getItem("token");
      if (token) {
        setIsAuthenticated(true);
        const firstTimeLogin = JSON.parse(localStorage.getItem("loginCount"));
        if (firstTimeLogin === 1) {
          toast.success("Welcome back!", {
            autoClose: 3000,
          });
          localStorage.setItem("loginCount", JSON.stringify(2));
        }
      } else {
        if (!toast.isActive("login-error")) {
          toast.error("Please login first!", {
            position: "top-right",
            autoClose: 2000,
            toastId: "login-error", // Unique ID to prevent duplicate toasts
          });
        }
        navigate("/");
      }
      setLoading(false);
    };

    setTimeout(checkAuth, 1500);

    const handleStorageChange = () => {
      checkAuth();
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [navigate]);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-900 text-white">
        {/* <ToastContainer /> */}
        <div className="text-center">
          <div className="w-16 h-16 border-4 border-blue-500 border-t-transparent rounded-full animate-spin mx-auto"></div>
          <p className="mt-4 text-lg font-semibold">Loading...</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <Navbar isSidebarOpen={isSidebarOpen} />
      <Homepage_Chat isSidebarOpen={isSidebarOpen} />
      {/* <Footer isSidebarOpen={isSidebarOpen} /> */}
    </>
  );
};
export default ArdraGPTNew;
