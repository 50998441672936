import React from "react";
import { cn } from "../../Utils/cn";

const Card = ({ className, children }) => {
    return (
        <div className={cn("bg-white shadow-md rounded-lg p-4", className)}>
            {children}
        </div>
    );
};

export default Card;
