import React, { useState } from "react";
import "./NewLandingPage.css"
import Button from "../ui/Button";
import Input from "../ui/Input";
import Card from "../ui/Card";
import CardContent from "../ui/CardContent";
import { FaCheckCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import IndianOilLogo from "../../Assist/newLandingPage/Indian_Oil_Corporation-Logo.wine.png"
import IciciLogo from "../../Assist/newLandingPage/ICICI Lombard.png"
import RelianceLogo from "../../Assist/newLandingPage/reliance.png"
import UpekhaLogo from "../../Assist/newLandingPage/partner.06587024b0b4a714757f.png"
import AwsLogo from "../../Assist/newLandingPage/Amazon_Web_Services-Logo.wine.svg"
export default function LandingPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const [error, setError] = useState("");

  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const handleInputChange = (e) => {
    setEmail(e.target.value);
    setError("");
  };

  async function addEmailToNotion(name, email) {
    try {
      const API_URL = process.env.REACT_APP_API_URL;
      const response = await axios.post(`${API_URL}/add-email`, {
        name: "New submission",
        email: email,
      });

      console.log("✅ Email added successfully!", response.data);
    } catch (error) {
      console.error(
        "❌ Error adding email:",
        error.response ? error.response.data : error.message
      );
    }
  }

  const handleSubmit = async () => {
    if (email.trim() === "") {
      setError("Email is required!");
      return;
    }

    if (!isValidEmail(email)) {
      setError("Enter a valid email!");
      return;
    }

    try {
      await addEmailToNotion("New submission", email);

      toast.success("Thank you! We've received your email.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
      setEmail("");
    } catch (error) { }
  };
  return (
    <div className="w-full min-h-screen bg-gradient-to-b from-white to-gray-100 text-gray-900 flex flex-col items-center p-8">
      <section className="text-center py-20 max-w-3xl mx-auto animate-fade-in px-3 ">
        <h1 className="text-4xl sm:text-5xl font-extrabold text-gray-900 leading-snug sm:leading-tight">
          Anybody Can Be a Data Scientist with
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-purple-600">
            {" "}
            Ardra Genie
          </span>
        </h1>

        <p className="mt-5 text-lg text-gray-700 max-w-2xl mx-auto">
          Upload your data, ask questions via voice, and get instant insights.
          <span className="font-semibold text-blue-600">
            {" "}
            No technical skills needed!
          </span>
        </p>

        <div className="mt-8 flex flex-col sm:flex-row gap-3 sm:justify-center items-center">
          <div className="bg-white/40 backdrop-blur-md shadow-lg rounded-lg p-1 flex gap-2 items-center border border-gray-300 w-full max-w-xs sm:max-w-[400px] h-[40px]">

            <Input
              type="email"
              value={email}
              placeholder="Enter your email"
              onChange={handleInputChange}
              className="w-full border-none focus:ring-0 bg-transparent text-gray-900 placeholder-gray-500 h-full"
            />
            {error && <p className="text-red-500 text-sm">{error}</p>}
          </div>
          <Button
            onClick={handleSubmit}
            className=" w-full sm:w-auto px-3 py-0 text-[12px] h-[40px] font-medium bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white rounded-lg shadow-md hover:shadow-lg transition-transform transform hover:scale-100 iphone-fix"
          >
            🚀 Get Early Access
          </Button>
        </div>
      </section>

      <section className="py-14 max-w-6xl text-center">
        <h2 className="text-3xl font-bold mb-8 text-gray-900">
          Why Choose Ardra Genie?
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <Card>
            <CardContent className="text-center">
              <FaCheckCircle className="text-5xl text-blue-500 mx-auto" />
              <p className="mt-6 text-lg text-gray-800 font-medium">
                AI-Powered Voice Analytics
              </p>
            </CardContent>
          </Card>
          <Card>
            <CardContent className="text-center">
              <FaCheckCircle className="text-5xl text-green-500 mx-auto" />
              <p className="mt-6 text-lg text-gray-800 font-medium">
                No Coding Required
              </p>
            </CardContent>
          </Card>
          <Card>
            <CardContent className="text-center">
              <FaCheckCircle className="text-5xl text-purple-500 mx-auto" />
              <p className="mt-6 text-lg text-gray-800 font-medium">
                Instant Data Insights
              </p>
            </CardContent>
          </Card>
        </div>
      </section>

      <section className="py-16 text-center">
        <h2 className="text-3xl font-bold text-gray-900">
          Be Among the First to Experience AI-Driven Analytics!
        </h2>
        <Button
          className="mt-8 px-8 py-4 bg-red-500 hover:bg-red-600"
          onClick={() => navigate("/login")}
        >
          Join the Beta Now
        </Button>
      </section>
      <section className="py-14 max-w-6xl text-center">
        <h2 className="text-3xl font-bold mb-8 text-gray-900">Our Key Customers</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <Card>
            <CardContent className="text-center">
              <img
                src={IndianOilLogo}
                alt="Indian Oil Corp"
                className="h-40 w-auto mx-auto object-contain"
              />
              {/* <p className="mt-4 text-lg font-semibold text-gray-800">Indian Oil Corp</p>
              <p className="mt-2 text-gray-600 text-sm">
                One of the largest oil corporations leveraging AI-powered energy optimization.
              </p> */}
            </CardContent>
          </Card>
          <Card>
            <CardContent className="text-center">
              <img
                src={RelianceLogo}
                alt="Reliance Retail"
                className="h-40 w-auto mx-auto object-contain"
              />
              {/* <p className="mt-4 text-lg font-semibold text-gray-800">Reliance Retail</p>
              <p className="mt-2 text-gray-600 text-sm">
                A leading retail giant using AI for real-time data insights and analytics.
              </p> */}
            </CardContent>
          </Card>
          <Card>
            <CardContent className="text-center">
              <img
                src={IciciLogo}
                alt="ICICI Lombard"
                className="h-44 w-auto mx-auto object-contain"
              />
              {/* <p className="mt-4 text-lg font-semibold text-gray-800">ICICI Lombard</p>
              <p className="mt-2 text-gray-600 text-sm">
                Transforming insurance analytics with AI-driven insights and automation.
              </p> */}
            </CardContent>
          </Card>
        </div>
      </section>

      <section className="py-14 max-w-6xl text-center">
        <h2 className="text-3xl font-bold mb-8 text-gray-900">
          Our Trusted Partners
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <Card>
            <CardContent className="text-center">
              <img
                src={UpekhaLogo}
                alt="Partner 1"
                className="h-[200px] w-auto mx-auto object-contain transition-transform duration-300 hover:scale-110"
              />
            </CardContent>
          </Card>
          <Card>
            <CardContent className="text-center">
              <img
                src={AwsLogo}
                alt="Partner 2"
                className="h-[90px] w-auto mx-auto object-contain mt-[60px] transition-transform duration-300 hover:scale-110"
              />
            </CardContent>
          </Card>
        </div>
      </section>

    </div>
  );
}
