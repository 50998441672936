import React from "react";
import { cn } from "../../Utils/cn";

const Button = ({ className, children, ...props }) => {
    return (
        <button
            className={cn(
                "px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg font-medium transition",
                className
            )}
            {...props}
        >
            {children}
        </button>
    );
};

export default Button;
