import React, { useState, useEffect, useRef } from "react";
import "./Homepage_Chat.css";
import {
  FaPlus,
  FaTimes,
  FaArrowUp,
  FaMicrophone,
  FaCopy,
  FaEdit,
} from "react-icons/fa";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import { WiStars } from "react-icons/wi";
import { AiOutlineReload } from "react-icons/ai";
import { IoCloseCircleOutline } from "react-icons/io5";
import { MdCode } from "react-icons/md";
import Lottie from "react-lottie";
import { Link, useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import audioWaveAnimation from "../../Assist/audioWaveAnimation.json";
import staticWaveSVG from "../../Assist/staticimage.svg";
import "react-toastify/dist/ReactToastify.css";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import FileUploadPopup from "../FileUploadpopup/FileUploadpopup";
import { useUser } from "../context/UserContext";
import DbConnectionDialogBox from "../OracleSql/DbConnectionDialogBox";
import { Tooltip } from "react-tooltip";
import { Helmet } from "react-helmet";
import { m } from "framer-motion";

import { Oval } from "react-loader-spinner";
import { set } from "lodash";

const Homepage_Chat = ({ isSidebarOpen }) => {
  const [messages, setMessages] = useState(
    JSON.parse(sessionStorage.getItem("messages")) || []
  );
  const [isListening, setIsListening] = useState(false);
  const [fileData, setFileData] = useState(null);
  const messagesEndRef = useRef(null);
  const [tableName, setTableName] = useState(sessionStorage.getItem("tableName") || "")

  useEffect(() => {
    sessionStorage.setItem("messages", JSON.stringify(messages));
  }, [messages]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const removeFile = () => {
    setFileName("");
    sessionStorage.removeItem("fileName");
    sessionStorage.removeItem("tableName");
    setFileData(null);
    setMessages([]);
  };

  const sendMessage = async (text) => {
    if (!text.trim() || !fileName) return;
    setQueryText("");
    setMessages((prev) => [...prev, { text, sender: "user" }]);
    setMessages((prev) => [
      ...prev,
      {
        text: "Generating text analysis...",
        sender: "ai",
        loading: true,
      },
    ]);
    await analyzeText(text);
  };

  const handleKeyDown = (event) => {
    console.log("first");
    if (event.key === "Enter") sendMessage(queryText);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Text copied to clipboard! 📋");
  };

  const editMessage = (index, text) => {
    setQueryText(text);
  };

  // ==========================================================================
  const API_URL = process.env.REACT_APP_API_URL;
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [queryText, setQueryText] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [analysisText, setAnalysisText] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState(
    JSON.parse(sessionStorage.getItem("fileName")) || ""
  );
  const [csvData, setCsvData] = useState([]);
  const [isDbDialogOpen, setDbDialogOpen] = useState(false);
  const [generateGraphLoading, setGenerateGraphLoading] = useState(false);

  const [dbType, setDbType] = useState("");
  const [tablesData, setTablesData] = useState([]);
  const [selectedTable, setSelectedTable] = useState("");
  const [csvTableData, setCsvTableData] = useState([]);
  const [error, setError] = useState(null);
  const [filetype, setFiletype] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { token, authToken } = useUser();
  const effectiveToken = token || authToken;
  const [textAnalysisResult, setTextAnalysisResult] = useState(null);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const location = useLocation();
  console.log(selectedTable, dbType)
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSelectFileType = (fileType) => {
    setFiletype(fileType);
    const fileInput = document.getElementById("file-upload");
    if (fileType === "csv") {
      fileInput.accept = ".csv";
      setSelectedTable("");
      setCsvTableData([]);
    } else if (fileType === "excel") {
      fileInput.accept = ".xlsx";
      setSelectedTable("");
      setCsvTableData([]);
    }
    fileInput.click();
  };
  useEffect(() => {
    if (isRecording) {
      const timer = setTimeout(() => {
        stopRecording();
      }, 15000);

      return () => clearTimeout(timer);
    }
  }, [isRecording]);

  const handleMicClick = () => {
    isRecording ? stopRecording() : startRecording();
  };

  const startRecording = async () => {
    try {
      setIsListening(true);
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      setMediaRecorder(recorder);
      recorder.start();
      setIsRecording(true);
      setIsProcessing(true);
      toast("Listening...", { icon: "🎤" });

      recorder.ondataavailable = async (e) => {
        const audioBlob = e.data;
        const audioUrl = URL.createObjectURL(audioBlob);
        // setAudioUrl(audioUrl);

        const formData = new FormData();
        formData.append("audio", audioBlob, "recording.wav");

        try {
          const response = await axios.post(
            `${API_URL}/voice_to_text`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${effectiveToken}`,
              },
            }
          );

          setQueryText(response.data.text);
          setIsProcessing(false);
          toast.success("Voice successfully converted to text! 😊");
        } catch (error) {
          console.error("Error converting voice to text", error);
          setIsProcessing(false);
          toast.error("Failed to convert voice to text.");
        }
      };
    } catch (error) {
      console.error("Error accessing microphone", error);
      setIsProcessing(false);
      toast.error("Failed to access microphone.");
    }
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      mediaRecorder.stream.getTracks().forEach((track) => track.stop());
      setIsRecording(false);
      setIsProcessing(true);
      toast("Processing...", { icon: "⚙️" });
    }
    setIsListening(false);
  };

  const analyzeText = async (transcribedText) => {
    setIsAnalyzing(true);
    const API_URL = process.env.REACT_APP_API_URL;

    try {
      toast("Generating text analysis...", { icon: "📝" });

      const response = await axios.post(
        `${API_URL}/generate-text-analysis`,
        {
          data: transcribedText,
          file_name: fileName,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${effectiveToken}`,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        setMessages((prev) => {
          if (prev[prev.length - 1]?.loading === true)
            return [
              ...prev.slice(0, prev.length - 1),
              { text: response.data.text_analysis, sender: "ai" },
            ];
          return [
            ...prev,
            { text: response.data.text_analysis, sender: "ai", loading: false },
          ];
        });
        setTextAnalysisResult(response.data);
        setAnalysisText(response.data.text_analysis);
        toast.success("Text analysis complete! 🎉");
      } else {
        toast.error(response.data.message || "Text analysis failed.");
        setMessages((prev) => {
          console.log(prev, "prev");
          if (prev[prev.length - 1]?.loading === true)
            return [
              ...prev.slice(0, prev.length - 1),
              { text: "Something went wrong", sender: "ai", loading: false },
            ];
        });
      }
    } catch (error) {
      console.error("Error analyzing text:", error);
      toast.error("Error analyzing text. Please try again.");
      setMessages((prev) => {
        console.log(prev, "prev");
        if (prev[prev.length - 1]?.loading === true)
          return [
            ...prev.slice(0, prev.length - 1),
            { text: "Something went wrong", sender: "ai", loading: false },
          ];
      });
    } finally {
      setIsAnalyzing(false);
    }
  };

  const generateGraph = async (query) => {
    try {
      let queryText;
      if (query) {
        queryText = query;
        setMessages((prev) => [...prev, { text: query, sender: "user" }]);
        setQueryText("");
      } else {
        const text = messages.findLastIndex(
          (message) => message.sender === "user"
        );
        queryText = messages[text].text;
      }

      setMessages((prev) => [
        ...prev,
        {
          text: "Generating graph...",
          sender: "ai",
          loading: true,
        },
      ]);
      const response = await axios.post(
        `${API_URL}/generate-graph`,
        {
          data: queryText,
          file_name: fileName,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${effectiveToken}`,
          },
        }
      );

      // for single graph
      if (response.status === 200 || response.status === 201) {
        setMessages((prev) => {
          if (prev[prev.length - 1]?.loading === true)
            return [
              ...prev.slice(0, prev.length - 1),
              {
                img: `${response.data.graph}`,
                text: "Graph is below",
                sender: "ai",
                loading: false,
              },
            ];
          return [
            ...prev,
            {
              img: `${response.data.graph}`,
              text: "Graph is below",
              sender: "ai",
              loading: false,
            },
          ];
        });
      } else {
        setMessages((prev) => {
          if (prev[prev.length - 1]?.loading === true)
            return [
              ...prev.slice(0, prev.length - 1),
              { text: "Something went wrong", sender: "ai", loading: false },
            ];
          return [
            ...prev,
            { text: "Something went wrong", sender: "ai", loading: false },
          ];
        });
      }

      toast.success("Graph generated! 📊");
    } catch (error) {
      setMessages((prev) => {
        if (prev[prev.length - 1]?.loading === true)
          return [
            ...prev.slice(0, prev.length - 1),
            { text: "Something went wrong", sender: "ai", loading: false },
          ];
        return [
          ...prev,
          { text: "Something went wrong", sender: "ai", loading: false },
        ];
      });
      console.error("Error generating graph:", error);
      toast.error("Failed to generate graph.");
    }
  };

  const clickGenerateGraph = async () => {
    if (queryText) {
      setGenerateGraphLoading(true);
      await generateGraph(queryText);
      setGenerateGraphLoading(false);
    } else {
      handleGenerateGraphs();
    }
  };

  const handleGenerateGraphs = async () => {
    if (
      !(
        messages.length > 0 &&
        messages.some((message) => message.sender === "user")
      )
    ) {
      toast("Please enter some query first!", { icon: "⚠" });
      return;
    }
    setGenerateGraphLoading(true);
    await generateGraph();
    setGenerateGraphLoading(false);
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const allowedExtensions = ["csv", "xlsx"];
    const fileExtension = file.name.split(".").pop().toLowerCase();
    console.log("first");

    if (!allowedExtensions.includes(fileExtension)) {
      toast.error("Only CSV or Excel files are allowed! 📄");
      return;
    }

    setSelectedFile(file);
    setFileName(file.name);
    sessionStorage.setItem("fileName", JSON.stringify(file.name));
    toast.success(`File selected: ${file.name} ✅`);

    const formData = new FormData();
    formData.append("file", file);

    try {
      await axios.post(`${API_URL}/upload`, formData, {
        headers: {
          Authorization: `Bearer ${effectiveToken}`,
        },
      });
      toast.success("File uploaded successfully!");

      toast("Now, please provide prompts/queries for analysis! 🎤", {
        icon: "👀",
      });
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("Failed to upload file.");
      return;
    }

    const fileType = file.type;

    if (fileType === "text/csv") {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target.result;
        const parsedData = Papa.parse(content, {
          header: true,
          skipEmptyLines: true,
        });
        setCsvData(parsedData.data);
      };
      reader.readAsText(file);
    } else if (
      fileType ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      fileType === "application/vnd.ms-excel"
    ) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheetName = workbook.SheetNames[0]; // Get the first sheet
        const worksheet = workbook.Sheets[firstSheetName]; // Get the actual sheet object
        const parsedData = XLSX.utils.sheet_to_json(worksheet); // Convert the sheet to JSON
        console.log(parsedData);
        setCsvData(parsedData);
      };
      reader.readAsArrayBuffer(file);
    } else {
      alert("Please upload a valid CSV or Excel file.");
    }
  };

  const handleOpenOracleDialog = (value) => {
    setDbType(value);
    setDbDialogOpen(true);
  };

  const handleCloseOracleDialog = () => {
    setDbDialogOpen(false);
  };

  const handleSpecificTable = async () => {
    const API_URL = process.env.REACT_APP_API_URL || "http://127.0.0.1:8050";

    try {
      const response = await axios.post(
        `${API_URL}/ingest-table`,
        selectedTable,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${effectiveToken}`,
          },
          responseType: "arraybuffer",
        }
      );

      const workbook = XLSX.read(new Uint8Array(response.data), {
        type: "array",
      });

      const sheetName = workbook.SheetNames[0];
      const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

      console.log(sheetData);
      setCsvTableData(sheetData);
      const tableName = typeof selectedTable === "string" ? selectedTable : selectedTable.table_name || "unknown_table";
      setTableName(tableName)
      setFileName("output_data.csv");
      sessionStorage.setItem("fileName", JSON.stringify("output_data.csv"));
      sessionStorage.setItem("tableName", JSON.stringify(tableName));

      setError(null);
      toast.success("Table data successfully fetched and displayed! 🎉");
    } catch (error) {
      console.error("Error fetching table data:", error);

      setError("Failed to fetch table data");
      toast.error("Error fetching table data. Please try again.");
    }
  };

  useEffect(() => {
    if (selectedTable) {
      setCsvData([]);
      handleSpecificTable();
    }
  }, [selectedTable]);

  return (
    <div className={isSidebarOpen ? "chat-div-open" : "chatDiv"}>
      <div className={isSidebarOpen ? "chat-container-open" : "chat-container"}>
        {messages.length === 0 && (
          <div className="welcome-message">
            <h2>Looking for solutions? I'm here to assist!</h2>
            <p>What can I help with?</p>
          </div>
        )}
        {!fileName && (
          <div className="file-warning">
            <h2>Please upload a file or select SQL to start chatting.</h2>
          </div>
        )}

        {messages.length > 0 && (
          <div className="chat-messages">
            {messages.map((msg, index) => (
              <div key={index} className={`message ${msg.sender}`}>
                {!msg.img && (
                  <span style={{ textAlign: "left", fontWeight: "500" }}>
                    {msg.text}
                  </span>
                )}
                {msg.loading && (
                  <div
                    className="loading-indicator"
                    style={{ display: "flex" }}
                  >
                    <ClipLoader size={20} color={"#0A1F44"} />
                  </div>
                )}
                {msg.img && (
                  <img
                    src={msg.img}
                    alt="Generated Graph"
                    className="generated-graph"
                  />
                )}
                {msg.sender === "ai" && !msg.img && !msg.loading && (
                  <button
                    className="copy-button"
                    onClick={() => copyToClipboard(msg.text)}
                    style={{
                      alignSelf: "flex-start",
                      marginTop: "5px",
                      marginRight: "5px",
                    }}
                  >
                    <FaCopy />
                  </button>
                )}
                {msg.sender === "user" && (
                  <button
                    className="edit-button"
                    onClick={() => editMessage(index, msg.text)}
                    style={{
                      alignSelf: "flex-start",
                      marginTop: "5px",
                      marginRight: "5px",
                    }}
                  >
                    <FaEdit />
                  </button>
                )}
              </div>
            ))}
            <div ref={messagesEndRef}></div>
          </div>
        )}

        {fileName && (
          <div className="file-name-container">
            <div className="file-name">
              {dbType === "sql" ? (tableName) : (fileName)}
              {" "}
              <button className="remove-file" onClick={removeFile}>
                <FaTimes />
              </button>
            </div>

            <button
              onClick={clickGenerateGraph}
              className="generate-button"
              disabled={generateGraphLoading}
              style={{
                margin: "0px",
              }}
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Click here to Gererate Graph"
              data-tooltip-place="bottom"
              data-tooltip-position-strategy="fixed"
            >
              {generateGraphLoading ? (
                <>
                  Generating Graph...
                  {/* <ClipLoader size={20} color={"#0A1F44"} /> */}
                </>
              ) : (
                <>
                  Generate Graph
                  <WiStars style={{ color: "#0A1F44", fontSize: "28px" }} />
                </>
              )}
            </button>
          </div>
        )}

        <div className="chat-box">
          <div className="file-upload">
            <div className="file-upload-container">
              <Tooltip
                id="my-tooltip"
                style={{
                  border: "1px solid red",
                  zIndex: "100",
                  backgroundColor: "#F8F8F8",
                  color: "black",
                  padding: "8px",
                  borderRadius: "4px",
                  width: "200px", // Set a fixed width to force wrapping
                  wordWrap: "break-word", // Ensure long words break
                  textAlign: "center",
                }}
              />
              <input
                type="file"
                onChange={handleFileUpload}
                className="file-input"
                id="file-upload"
                style={{ display: "none" }}
              />

              <FileUploadPopup
                isOpen={isModalOpen}
                onClose={closeModal}
                onSelect={handleSelectFileType}
                onOracleSelect={handleOpenOracleDialog}
              />

              {isDbDialogOpen && (
                <DbConnectionDialogBox
                  onClose={handleCloseOracleDialog}
                  dbType={dbType}
                  setTablesData={setTablesData}
                  setSelectedTable={setSelectedTable}
                />
              )}
            </div>
            <button className="menu-button" onClick={openModal}>
              {<FaPlus />}
            </button>
          </div>

          {/* Input field and buttons are disabled until a file is uploaded */}
          <input
            type="text"
            className="chat-input"
            placeholder={
              fileName
                ? isListening
                  ? "Listening..."
                  : isProcessing
                    ? "Processing..."
                    : "Message ardraGPT..."
                : "Upload a file first..."
            }
            value={queryText}
            onChange={(e) => setQueryText(e.target.value)}
            onKeyDown={handleKeyDown}
            disabled={!fileName}
          />

          <button
            className={`voice-button ${isRecording ? "active" : ""}`}
            onClick={handleMicClick}
            disabled={!fileName}
          >
            <FaMicrophone />
          </button>

          <button
            className="send-button"
            onClick={() => sendMessage(queryText)}
            disabled={!fileName}
          >
            <FaArrowUp />
          </button>
        </div>
        <div className="chat-features flex justify-center gap-6 mt-4">
          <span className="feature-label">Data Files Analysis with ardraGPT</span>
          <span className="feature-label">Generate Graph</span>
          <span className="feature-label">Text Analysis</span>
          <span className="feature-label">Data Analysis</span>
        </div>


      </div>
      {/* Version Switch Button - Bottom Right */}
      <div className="version-switch-container">
        {location.pathname === "/ardraGPTPage" ? (
          <Link to="/ardraGPTPage2">
            <button className="version-switch-btn">Go to Old Version</button>
          </Link>
        ) : location.pathname === "/ardraGPTPage2" ? (
          <Link to="/ardraGPTPage">
            <button className="version-switch-btn">Go to New Version</button>
          </Link>
        ) : null}
      </div>
      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
};

export default Homepage_Chat;
