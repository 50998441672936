import React from "react";
import { cn } from "../../Utils/cn";

const Input = ({ className, ...props }) => {
    return (
        <input
            className={cn(
                "w-full p-3 border border-gray-300 rounded-lg focus:ring focus:ring-blue-300 outline-none",
                className
            )}
            {...props}
        />
    );
};

export default Input;
