import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import "../src/styles/tailwind.css";
import LandingPage from "./Pages/LandingPage";
import ArdraGPTPage from "./Pages/ardraGPTPage";
import Profile from "./Component/Profile/Profile";
import PromptHistoryTable from "./Component/PromptHistoryTable/PromptHistoryTable";
import { UserProvider, useUser } from "./Component/context/UserContext";
import BuyToken from "./Component/BuyToken/BuyToken";
import { ToastContainer } from "react-toastify";
import TermsAndConditions from "./Component/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "./Component/PrivacyPolicy/PrivacyPolicy";
import ReactGA from "react-ga4";
import Homepage_Chat from "./Component/HomepageChat/Homepage_Chat";
import ArdraGPTNew from "./Pages/ardraGPTNew";
import NewLandingPage from "./Component/NewLandingPage/NewLandingPage";

function App() {
  const { setUserId, setToken } = useUser();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const token = params.get("token");

  useEffect(() => {
    ReactGA.initialize("G-G0B4YWFVZS");

    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  useEffect(() => {
    if (token) {
      setToken(token);

      const firstTimeLogin = JSON.parse(localStorage.getItem("loginCount"));
      if (!firstTimeLogin) {
        localStorage.setItem("loginCount", JSON.stringify(1));
      }
    }
  }, [token]);

  return (
    <>
      <Routes>
        <Route path="/login" element={<LandingPage />} />
        <Route path="/ardraGPTPage2" element={<ArdraGPTPage />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/prompt" element={<PromptHistoryTable />} />
        <Route path="/buyToken" element={<BuyToken />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/ardraGPTPage" element={<ArdraGPTNew />} />
        <Route path="/" element={<NewLandingPage />} />
      </Routes>

      <ToastContainer />
    </>
  );
}

export default () => (
  <UserProvider>
    <App />
  </UserProvider>
);

// const Layout = () => {
//   const navigate = useNavigate();

//   const handleSettingsClick = () => {
//     navigate('/settings');
//   };

//   return (
//     <div className="flex h-screen">

//       <Sidebar1 onSettingsClick={handleSettingsClick} />

//       <div className="flex-1 overflow-y-scroll p-6 bg-gray-100">
//         <Routes>
//           <Route path="/" element={<VoiceRecorder />} />
//           <Route path="/settings/*" element={<SettingsLayout />} />
//         </Routes>
//       </div>
//     </div>
//   );
// };

// <LandingPage/>

//base
// <>
//     <Navbar/>
//     <VoiceRecorder/>
//     <Footer/>
//   </>

//sidebar & base
// <Router>
//   <div className="App">
//     <Routes>
//       <Route path="/*" element={<Layout />} />
//     </Routes>
//   </div>
// </Router>
